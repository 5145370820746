.section3 {
  height: 667px;
  position: relative;
  background-color: #fef7ef;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 72px;
  color: #6a4b34;
}

.topTextFrame {
  padding-bottom: 25px;
}

.scrollingRecipes {
  position: relative;
  width: 100%;
  height: 190px;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
}
.recipeTrack {
  display: flex;
  gap: 10px;
  left: 0;
  animation: scroll 300s linear infinite;
}
.recipeTrack2 {
  display: flex;
  position: absolute;
  right: 0;
  height: fit-content;
  gap: 10px;
  animation: scroll2 300s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes scroll2 {
  0% {
    transform: translateX(o);
  }
  100% {
    transform: translateX(100%);
  }
}

.recipe {
  flex-shrink: 0;
  width: 140px;

}
.bottomFrame {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}