.kitchenIcon {
  	width: 10px;
  	position: relative;
  	/* height: 15px; */
}
.b {
  	position: relative;
}
.timeIndicator {
  	border-radius: 5px;
  	background-color: #fef7ef;
  	display: flex;
  	flex-direction: row;
  	align-items: center;
  	justify-content: center;
  	padding: 1px 2px;
  	gap: 2px;
}
.image {
  	align-self: stretch;
  	height: 126px;
  	overflow: hidden;
  	flex-shrink: 0;
  	display: flex;
  	flex-direction: column;
  	align-items: flex-start;
  	justify-content: flex-end;
  	padding: 5px;
  	box-sizing: border-box;
  	background-image: url('https://www.skinnytaste.com/wp-content/uploads/2023/07/BBQ-Chicken-8.jpg');
  	background-size: cover;
  	background-repeat: no-repeat;
  	background-position: top;
  	z-index: 0;
}
.recipesName {
  	position: relative;
  	overflow: hidden;
  	text-overflow: ellipsis;
  	white-space: nowrap;
}
.recipeTinEats {
  	position: relative;
  	font-size: 10px;
  	color: #504f4f;
  	overflow: hidden;
  	text-overflow: ellipsis;
  	white-space: nowrap;
}
.bottom {
  	align-self: stretch;
  	flex: 1;
  	background-color: #fef7ef;
  	overflow: hidden;
  	display: flex;
  	flex-direction: column;
  	align-items: flex-start;
  	justify-content: center;
  	padding: 2px 7px;
  	gap: 1px;
  	z-index: 1;
  	font-size: 16px;
}
.bookmarkIcon {
  	width: 24px;
  	position: absolute;
  	margin: 0 !important;
  	top: 3px;
  	left: 112px;
  	height: 24px;
  	z-index: 2;
}
.recepie {
  	width: 140px;
  	position: relative;
  	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  	border-radius: 10px;
  	background-color: #fef7ef;
  	height: 171px;
  	overflow: hidden;
  	flex-shrink: 0;
  	display: flex;
  	flex-direction: column;
  	align-items: flex-start;
  	justify-content: flex-start;
  	text-align: center;
  	font-size: 12px;
  	color: #6a4b34;
}

