.Home-header {
  background-color: #282c34;
  color: white;
  text-align: center;
  padding: 60px 0;
}

.Home-header h1 {
  font-size: 3em;
  margin-bottom: 10px;
}

.Home-section {
  display: flex;
  padding: 60px 10%;
  align-items: center;
}

.Home-section.reverse {
  flex-direction: row-reverse;
}

.Home-section-content {
  flex: 1;
  padding: 0 20px;
}

.Home-section-content h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.Home-section-content p {
  font-size: 1.2em;
  line-height: 1.6;
}

.Home-section-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.placeholder-image {
  width: 300px;
  height: 200px;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  color: #666;
}
