
.footer {
    background-color: #282c34;
    color: white;
    text-align: center;
    padding: 20px 0;
  }
  
.footer nav {
margin-top: 10px;
}

.footer nav a {
color: white;
text-decoration: none;
margin: 0 10px;
}

.footer nav a:hover {
text-decoration: underline;
}