.hero {
  width: 100%;
  height: 85vh; /* Adjust height to full viewport height */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  background-color: #FFFCF9;
  padding-top:15px;
}

.showcase-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.showcase-image {
  height: auto; /* Maintain aspect ratio */
  width: 100%; /* Set specific width for larger screens */
  max-width: 500px;
}


h1 {
  color: #6A4B34;
  font-size: 4.5rem; /* Responsive font size */
  text-align: left;
  line-height: 1.2; /* Maintain line height ratio */
  margin: 2rem 0; /* Responsive margins */
}

h2 {
  color: #6A4B34;
  font-size: 3.5rem; /* Responsive font size */
  text-align: left;
  line-height: 1.2; /* Maintain line height ratio */
  margin: 2rem 0; /* Responsive margins */
}

h3 {
  color: #6A4B34;
  font-weight: 600;
  font-size: 2rem; /* Responsive font size */
  margin: 1rem 0; /* Responsive margins */
}

/* Media query for smaller screens */


@media screen and (max-width: 768px) {

  .hero {
    flex-direction: column;
    gap: 0;
    height: 85vh; /* Adjust height to full viewport height */
    padding: 0px;
  }

  .showcase-image {
    width: 70%; /* Set specific width for larger screens */
    max-width: 550px; /* Set maximum width */
  }

  h1 {
    font-size: 2.5rem;
    text-align: center;
  }

  h2 {
    font-size: 2rem;
    text-align: center;
    margin: 0px 0px 20px 0px;
  }

  h3 {
    font-size: 1.5rem;
  }
}