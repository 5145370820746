.navbar {
  width: -webkit-fill-available;
  z-index: 9999;
  padding: 15px 50px;
  background: #FEF7EF;
  box-shadow: 0px 4px 4px rgba(105.52, 75.25, 51.70, 0.1);
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
  position: sticky; 
  top: 0;
}



.logo-container {
  padding: 5px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.logo {
  width: 40px;
  height: 40px;
}

.app-name {
  color: #6A4B34;
  font-size: 25.67px;
  font-weight: 700;
  line-height: 38.50px;
  word-wrap: break-word;
  text-decoration: none; /* Remove underline */
}

.nav-links {
  flex: 1 1 0;
  height: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
}

.nav-item {
  color: #6A4B34;
  font-size: 17px;
  text-decoration: none;
  font-weight: 700;
  word-wrap: break-word;
}

/* Media query for phone-sized screens */
@media screen and (max-width: 768px) {
  .nav-item, .nav-links, .hero-button {
    display: none;
  }

  .navbar {
    justify-content: center;
    position: relative;
    background: #FFFCF9;
    box-shadow: none;
    padding-bottom: 10px;
  }

  .app-name {
    font-size: 2.5rem;
  }
}