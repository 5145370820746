.hero-button-large, .hero-button-small {
  position: relative;
  background-color: #83b57f;
  border: none;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.hero-button-large {
  padding: 13px 20px;
  border-radius: 16px;
  transition: box-shadow 0.3s;
  box-shadow: inset 0 -4px 0 0 #6ea170;
}

.hero-button-small {
  padding: 10px 15px;
  border-radius: 12px;
}

.hero-button-large:hover {
  box-shadow: inset 0 -8px 0 0 #6ea170;
}

.hero-button-small:hover {
  box-shadow: inset 0 -4px 0 0 #6ea170;
}

.hero-button-large:active {
  background-color: #6ea170;
  box-shadow: inset 0 -4px 0 0 #83b57f;
}

.hero-button-small:active {
  background-color: #6ea170;
  box-shadow: inset 0 -2px 0 0 #83b57f;

}

.button-text-large, .button-text-small {
  font-family: 'SF Pro Rounded', 'SF Pro Display', sans-serif;
  color: #fff;
}

.button-text-large {
  font-weight: 600;
  font-size: 30px;
  transition: transform 0.3s;
}

@media screen and (max-width: 768px) {
  .button-text-large {
    font-size: 24px;
  }
}

.button-text-small {
  font-weight: 600;
  font-size: 17px;
  transition: transform 0.2s;
}

.hero-button-large:hover .button-text-large {
  transform: rotateX(15deg) translateY(-4px);
}

.hero-button-small:hover .button-text-small {
  transform: rotateX(15deg) translateY(-2px);
}

.hero-button-large:active .button-text-large, .hero-button-small:active .button-text-small {
  transform: translateY(0);
}

.hero-button-wrapper {
  height: 70px;
}
